import React from 'react'
import "./mystyles.scss"
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/font-awesome'
import SEO from '../components/SEO'

export default function coaching() {
    return (
        <Layout>
            <SEO />
            <section className="container has-text-light pb-5">
                <p className="title has-text-primary">Coaching</p>
                <div className="columns pb-5">
                    <div className="column">
                        <StaticImage 
                            src="../images/nik-macmillan-YXemfQiPR_E-unsplash.jpg"
                            alt="two people seated at table"
                            placeholder="blurred"
                        />
                    </div>
                    <div className="column">
                        <p className="subtitle has-text-light p-6 is-4">Top Talent offers individual and team coaching to inspire and develop positive mindsets. Using tools including IPEC’s Core Energy Leadership Framework™ and  Shirzad Chamine's Positive Intelligence Program, ICF-accredited trained certified coaches guide clients in revealing a deeper level of consciousness, optimizing sustainable happiness and success. </p>
                        
                    </div>
                </div>
            </section>
            <section className="container has-text-light pb-5">
                <div>
                    <p className="title is-4 has-text-primary pt-5">Powerful coaching methods that lead to long-term success</p>
                    <p className="pb-5"><span className="has-text-weight-bold has-text-primary">Option 1.</span> Begin with a 10-minute assessment and a 60-minute one-on-one confidential coaching session. Then, engage in the 6-week virtual Positive Intelligence Program which combines weekly 1-hour video sessions, 45-minute weekly facilitated meetings with 3-5 other individuals participating in the program, and an exclusive APP with daily exercises and tools. Complete the program with a second one-on-one coaching session to confirm sustainable success. The Positive Intelligence Program boosts the 3 core muscles of mental fitness: Self-command, conquering Saboteurs and building your Sage. Top Talent’s coaching leverages these scientific metric-backed tools. The experience is often described as life changing.</p>
                    <p className="pb-5"><span className="has-text-weight-bold has-text-primary">Option 2.</span> Engage in a 12-hour coaching engagement, typically in 60-90 minute sessions over a 3-6 month period. This includes a 30-minute Energy Leadership Index Assessment, followed by a 90-minute debrief focusing on understanding and increasing your energy levels during both good and stressful times. The focus during coaching is solely on you and your goals. We use iPEC’s Core Energy Coaching™ methodology to get to the root of what is blocking your ability to achieve greater productivity, happiness and success. This approach brings your thoughts, feelings, and words to the surface, leading to enlightened perspectives, and ultimately actions that achieve extraordinary results for you and the people you work with. The process is compassionate and effective, with sustainable results for thousands of individual contributors, managers and executives.</p>
                    <p className="pb-5 has-text-weight-bold is-size-4">Options 1 and 2 are often combined, beginning first with option 1 and then proceeding to option 2.</p>
                </div>
                <div>
                    <p className="title is-4 has-text-primary pt-5">Develop a plan of action and accountability</p>
                    <div className="columns">
                        <div className="column is-8">
                            <p className="pb-5">Like all individuals, you view the world through expectations based on your experiences, values, and assumptions. These expectations may either limit or expand your perspective due to unconsciously developed filters that impact how you perceive things, how you react emotionally, and how you make decisions. Those filters ultimately affect how you show up in different situations and might be holding you back from realizing the full potential in yourself, your life, and your career.</p>
                            <p className="pb-5">As part of the coaching process, including your participation in one or both of the assessments, you will discover how these filters influence what you are achieving. Together, we review assessment outputs during our coaching conversations to create a plan of action and accountability that will distinguish you as a leader and most importantly as a happier more successful person with stronger relationships and peace of mind.</p>
                        </div>
                        <div className="column is-4">
                            <StaticImage 
                                src="../images/person-holding-white-stylus-768472.jpg"
                                alt="person holding white stylus"
                                placeholder="blurred"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="container has-text-light pb-5">
                <p className="subtitle is-5 has-text-light">With improved leadership capabilities you will have the ability to:</p>
                <div className="columns">
                    <div className="column">
                        <ul>
                            <li className="p-4 pl-5 is-size-4"><FontAwesomeIcon icon={'images'} size="1x" /> Develop and implement stronger strategies</li>
                            <li className="p-4 pl-5 is-size-4"><FontAwesomeIcon icon={'users'} size="1x" /> Create high-performing teams</li>
                            <li className="p-4 pl-5 is-size-4"><FontAwesomeIcon icon={'comments'} size="1x" /> Collaborate effectively</li>
                        </ul>
                    </div>
                    <div className="column">
                        <ul>
                            <li className="p-4 is-size-4"><FontAwesomeIcon icon={'user-friends'} size="1x" /> Build new and expanded relationships</li>
                            <li className="p-4 is-size-4"><FontAwesomeIcon icon={'lightbulb'} size="1x" /> Contribute innovative ideas</li>
                            <li className="p-4 is-size-4"><FontAwesomeIcon icon={'user-shield'} size="1x" /> Become more resilient and adaptable to change</li>
                        </ul>
                    </div>
                </div>
                <p className="pb-5 is-size-3 has-text-primary has-text-centered">These capabilities can lead to increased productivity and success for you, your team members and your organization.</p>
            </section>
        </Layout>
    )
}
